import * as React from "react";
import {IFormFieldProps} from "./FormField";
import {Redirect} from "react-router";

export class Form extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const errors: IErrors = {};
    const values: IValues = {};
    this.state = {
      errors,
      values
    };
  }

  private handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();
    if (this.validateForm()) {
      const submitSuccess: boolean = await this.submitForm();
      this.setState({submitSuccess});
    }
  }

  private haveErrors(errors: IErrors) {
    let haveError: boolean = false;
    Object.keys(errors).map((key: string) => {
      if (errors[key].length > 0) {
        haveError = true;
      }
    });
    return haveError;
  }

  private validateForm(): boolean {
    const errors: IErrors = {};
    Object.keys(this.props.fields).map((fieldName: string) => {
      errors[fieldName] = this.validate(fieldName);
    });
    this.setState({ errors });
    return !this.haveErrors(errors);
  }

  private async submitForm(): Promise<boolean> {
    const object = {customer: this.state.values}
    await fetch(this.props.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object)
    })
      .then(res => res.json())
    return true;
  }

  private setValues = (values: IValues) => {
    this.setState({
      values: {
        ...this.state.values,
        ...values
      }
    });
  };

  private updateValue = (key: string, value: string) => {
    this.state.values[key] = value;
  };

  private validate = (fieldName: string): string => {
    let newError: string = "";

    if (
      this.props.fields[fieldName] && this.props.fields[fieldName].validation
    ) {
      newError = this.props.fields[fieldName].validation!.rule(
        this.state.values,
        fieldName,
        this.props.fields[fieldName].validation!.args
      );
    }
    this.setState({
      errors: {...this.state.errors, [fieldName]: newError}
    });
    return newError;
  };

  render() {
    const {submitSuccess} = this.state;
    const context: IContext = {
      ...this.state,
      setValues: this.setValues,
      updateValue: this.updateValue,
      validate: this.validate
    };
    return (
      <div className="form-wrapper">
        {submitSuccess && (
          <Redirect to={'/danke'} />
        )}
        <FormContext.Provider value={context}>
          <form onSubmit={this.handleSubmit} noValidate={true} className="form">
            {this.props.render()}
          </form>
        </FormContext.Provider>
      </div>
    );
  }
}

export const FormContext = React.createContext<IContext | null>(null);

export const required = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === ''
    ? 'Das Feld ist ein Pflichtfeld'
    : '';

export const isEmail = (values: IValues, fieldName: string): string => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const result = regEx.test(String(values[fieldName]).toLowerCase());
  return values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === '' ||
  !result
    ? 'Bitte geben Sie eine gültige E-Mailadresse ein'
    : '';
}

export const isTelephone = (values: IValues, fieldName: string): string => {
  const regEx = /^([(+|00)](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/;
  const result = regEx.test(String(values[fieldName]).toLowerCase());
  return values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === '' ||
  !result
    ? 'Bitte geben Sie eine gültige Telefonnummer ein z.B +49 9181 3300'
    : '';
}

export const isNumeric = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === '' ||
  Number.isInteger(values[fieldName])
    ? 'Bitte geben Sie nur Ziffern ein'
    : '';

export const isHousenumber = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === '' ||
  values[fieldName].search(/^\d+[a-zA-Z]{0,1}$/)
    ? 'Bitte geben Sie eine gültige Hausnummer ein'
    : '';

export const isCustomernumber = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === '' ||
  values[fieldName].search(/^\d{8}$/)
    ? 'Bitte geben Sie eine gültige 8-stellige Kundennummer ein'
    : '';

export const checkIsAdult = (values: IValues, fieldName: string): string =>
  values[fieldName] === undefined ||
  values[fieldName] === null ||
  values[fieldName] === '' ||
  !isAdult(values[fieldName])
    ? 'Bitte geben Sie ein gültiges Datum ein. Sie müssen mindestens 18 Jahre alt sein'
    : '';

const isAdult = (birthday: string): boolean => {
  const currDate = new Date();
  const birthDate = new Date(birthday);
  const age = Math.floor((currDate.getTime() - birthDate.getTime()) / (1000 * 60 * 60 * 24 * 365.25));
  return age >= 18;
}

interface IProps {
  action: string
  fields: IFields;
  render: () => React.ReactNode;
}

interface IState {
  values: IValues
  errors: IErrors
  submitSuccess?: boolean;
}

export interface IFields {
  [key: string]: IFormFieldProps;
}

export interface IValues {
  [key: string]: any
}

export interface IErrors {
  [key: string]: string
}

export interface IContext extends IState {
  setValues: (values: IValues) => void;
  updateValue: (key: string, value: string) => void;
  validate: (fieldName: string) => void;
}