import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconLookup, IconDefinition, findIconDefinition, IconName} from "@fortawesome/fontawesome-svg-core";

interface IProps {
  id: string,
  classNames: string,
  linkText: string,
  faIconName: IconName,
  hide: boolean,
}

export class ClearForm extends React.Component <IProps, {  }> {
  iconLookup: IconLookup = {prefix: 'fas', iconName: this.props.faIconName};
  faIcon: IconDefinition = findIconDefinition(this.iconLookup);

  render() {
    if (this.props.hide) {
      return (
        <div id={this.props.id} className={this.props.classNames}>
          <span className="icon-wrapper-circle">
            <FontAwesomeIcon icon={this.faIcon} />
          </span>
          {this.props.linkText}
        </div>
      );
    }
    return null;
  }
}
