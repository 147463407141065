import * as React from 'react';

export class PriceBlock extends React.Component <IProps> {
  render() {
    let myClassName: string;
    if (this.props.short) {
      myClassName = "price-block price-block__short";
    } else {
      myClassName = "price-block pl-3";
    }
    if (this.props.oldPrice) {
      return (
        <div className={myClassName}>
          <span className={this.props.short ? "old-price" : "old-price d-block"}>
            bisher {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.props.oldPrice)}
          </span>
          <span className={this.props.short ? "current-price pl-2" : "current-price d-block"}>
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.props.currentPrice)}
          </span>
        </div>
      );
    } else {
      return (
        <div className={myClassName}>
          <span className={"price"}>
            {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(this.props.currentPrice)}
          </span>
        </div>
      );
    }
  }
}

interface IProps {
  oldPrice: number | null,
  currentPrice: number,
  short: boolean
}
