import * as React from 'react';
import {Carousel} from "react-responsive-carousel";

export class MediaBlock extends React.Component <IProps> {
  render() {
    const items = this.props.images.map((image:any) => {
        return (
          <div key={image}>
            <img src={image} alt="Produktbild"/>
          </div>
        );
    });

    return (
      <Carousel showIndicators={false} showStatus={false} autoPlay={false} dynamicHeight={false} showArrows={false}
                thumbWidth={100}>
        {items}
      </Carousel>
    );
  }
}

interface IProps {
  images: any,
  video: string
}
