import React from 'react';
import {Link, Redirect} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../fontawesome';
import Background from '../images/Startseite_Hintergrund.jpg';

export class Produktsuche extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.props.toggleIsHome(false);
    this.state = {
      redirect: null
    }
    this.loadProduct = this.loadProduct.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    const inputField: HTMLElement | null = document.getElementById('artikelnr');
    if (inputField) {
      inputField.addEventListener("keyup", this.handleKeyDown, false);
    }
  }

  handleKeyDown(ev: KeyboardEvent) {
    const {keyCode} = ev;
    if (keyCode === 13) {
      this.loadProduct();
    }
  }

  loadProduct() {
    let artikelnr: string = (document.getElementById('artikelnr') as HTMLInputElement).value;
    if (artikelnr.length > 5) {
      if (artikelnr.substr(5, 1) !== '0') {
        artikelnr = artikelnr.substr(0, 5) + '0'
      }
    } else if (artikelnr.length === 5) {
      artikelnr = artikelnr + '0'
    }
    this.setState({redirect: ('/produkt/' + artikelnr)});
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>
    }
    return (
      <main id="content">
        <div id="start" className="page level-0">
          <div id="background-image" className="container">
            <img src={Background} alt={'berger'}/>
          </div>
          <div className="container">
            <div className="row justify-content-center flex-grow-1">
              <div className="card">
                <div className="kachel vorteilskarte w-100">
                  <div className="input-group input-group-lg mb-5 bg-white">
                    <input id="artikelnr" type="text" className="form-control" placeholder="Artikelnummer (z.B. 123456)"
                           inputMode="numeric" aria-label="Artikelnummer" aria-describedby="btn-artikelsuche" />
                    <div className="input-group-append">
                      <button id="btn-artikelsuche" className="btn btn-primary"
                              type="button" onClick={this.loadProduct}>
                        Suchen
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between w-100">
                    <span>Suche nach<br/>Artikelnummer</span>
                  </div>
                </div>
              </div>
              <div className="card">
                <Link to="/produkt" className="kachel produktscan w-100">
                  <FontAwesomeIcon icon={['fas', 'barcode']}/>
                  <div className="d-flex justify-content-between w-100">
                    <span>Barcode<br/>scannen</span>
                    <span><FontAwesomeIcon icon={['fas', 'chevron-right']}/></span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

interface IProps {
  toggleIsHome: (newIsHomeState: boolean) => any
}

interface IState {
  redirect: string | null;
}
