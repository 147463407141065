import * as React from "react";
import {useContext, useEffect} from "react";
import {FormContext, IErrors, IValues} from "./Form";
import {Subtext} from "./Subtext";

type Editor = "inputfield" | "select" | "checkbox";
type InputfieldTypes = "text" | "password" | "date" | "email" | "number" | "tel" | "hidden";
type InputModes = "text" | "numeric" | "email" | "url";


export const FormField: React.FunctionComponent<IFormFieldProps> = ({
  id,
  label,
  editor,
  options,
  value,
  type,
  inputmode,
  validation,
  subtext,
  defaultValue,
  minValue,
  pattern,
  disabled,
}) => {
  const getError = (errors: IErrors): string => {
    return (errors ? errors[id!.toString()] : '');
  }
  const context = useContext(FormContext);
  useEffect(() => {
    if (value && type === 'hidden') {
      context?.updateValue(id!.toString(), value);
    }
  });
  return (
    <FormContext.Consumer>
      {context => context && (
        <div className={"form-group" + (editor!.toLowerCase() === 'checkbox' ? ' form-check' : '')}>
          {editor!.toLowerCase() === "checkbox" && (
            <input
              id={id}
              name={id}
              type="checkbox"
              value={value}
              onChange={
                (e: React.FormEvent<HTMLInputElement>) => {
                  context.setValues({[e.currentTarget.name]: e.currentTarget.value});
                }
              }
              onBlur={
                (e: React.FormEvent<HTMLInputElement>) => {
                  context?.validate(e.currentTarget.name);
                }
              }
              className={"form-check-input" + (getError(context.errors) ? ' is-invalid' : '')}
            />
          )}

          {label && <label htmlFor={id}
                           className={editor!.toLowerCase() === 'checkbox' ? "form-check-label" : ""}>
                      {label + (validation ? ' *' : '')}
                      {subtext && <Subtext type={subtext} />}
                    </label>
          }

          {editor!.toLowerCase() === "inputfield" && (
            <input
              id={id}
              className={"form-control" + (getError(context.errors) ? ' is-invalid' : '')}
              name={id}
              type={type}
              value={value}
              defaultValue={defaultValue}
              inputMode={inputmode}
              min={minValue}
              pattern={pattern}
              disabled={disabled}
              onChange={
                (e: React.FormEvent<HTMLInputElement>) => {
                  context.setValues({[e.currentTarget.name]: e.currentTarget.value});
                }
              }
              onBlur={
                (e: React.FormEvent<HTMLInputElement>) => {
                  context?.validate(e.currentTarget.name);
                }
              }
            />
          )}

          {type!.toLowerCase() === "hidden" && (
            <div className="form-group">
              <div className="form-control">{value}</div>
            </div>
          )}

          {editor!.toLowerCase() === "select" && (
            <select
              id={id}
              name={id}
              value={value}
              onChange={
                (e: React.FormEvent<HTMLSelectElement>) => {
                  context.setValues({[e.currentTarget.name]: e.currentTarget.value});
                }
              }
              onBlur={
                (e: React.FormEvent<HTMLSelectElement>) => {
                  context?.validate(e.currentTarget.name);
                }
              }
              className={"form-control" + (getError(context.errors) ? ' is-invalid' : '')}
            >
              {options &&
              options.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label ? option.label : option.value}
                </option>
              ))}
            </select>
          )}

          {getError(context.errors) && (
            <div className="invalid-feedback">{getError(context.errors)}</div>
          )}
        </div>
      )}
    </FormContext.Consumer>
  );
}

FormField.defaultProps = {
  editor: "inputfield",
  type: "text"
};

export interface IFormFieldProps {
  id?: string
  label?: string
  editor?: Editor
  options?: IOptions[]
  value?: any
  type?: InputfieldTypes
  inputmode?: InputModes
  validation?: IValidation
  subtext?: string
  defaultValue?: string
  minValue?: string
  pattern?: string
  disabled?: boolean
}

export interface IOptions {
  value: string
  label?: string
}

export interface IValidation {
  rule: (values: IValues, fieldName: string, args: any) => string
  args?: any
}
