import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconLookup, IconDefinition, findIconDefinition, IconName} from "@fortawesome/fontawesome-svg-core";

interface IProps {
  id: string,
  classNames: string,
  linkText: string,
  faIconName: IconName,
  hide: boolean,
  toggleShowBack: (newShowBackState: boolean) => any,
  toggleIsForm: (newIsFormState: boolean) => any,
  isHome: boolean,
}

export class LinkBack extends React.Component <IProps, {  }> {
  iconLookup: IconLookup = {prefix: 'fas', iconName: this.props.faIconName};
  faIcon: IconDefinition = findIconDefinition(this.iconLookup);

  handleClick() {
    window.history.back();
    this.props.toggleIsForm(false);
  }

  render() {
    if (this.props.hide || !this.props.isHome) {
      return (
        <div id={'link-' + this.props.id} className={this.props.classNames} onClick={this.handleClick.bind(this)}>
          <span className="icon-wrapper-circle">
            <FontAwesomeIcon icon={this.faIcon} />
          </span>
          {this.props.linkText}
        </div>
      );
    }
    return null;
  }
}
