import React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconLookup, IconDefinition, findIconDefinition, IconName} from "@fortawesome/fontawesome-svg-core";

interface INavIconProps {
  id: string,
  classNames: string,
  linkTo: string,
  linkText: string,
  faIconName: IconName,
  hide: boolean,
  toggleIsHome: (newIsHomeState: boolean) => any
}

export class NavIcon extends React.Component <INavIconProps, {  }> {
  iconLookup: IconLookup = {prefix: 'fas', iconName: this.props.faIconName};
  faIcon: IconDefinition = findIconDefinition(this.iconLookup);

  render() {
    if (!this.props.hide) {
      return (
        <div id={'link-' + this.props.id} className={this.props.classNames}>
          <Link to={this.props.linkTo} className="icon-wrapper-circle" onClick={() => this.props.toggleIsHome(true)}>
            <FontAwesomeIcon icon={this.faIcon} />
          </Link>
          {this.props.linkText}
        </div>
      );
    }
    return null;
  }
}
