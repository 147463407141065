import * as React from 'react';
import {Footer} from "../components/Footer";
import ProductscanImage from "../images/Barcodescan.svg"
import {Redirect} from "react-router";
import {Toast} from "../components/Toast";

export class Productscan extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ean: '',
      redirect: null,
      toasts: [],
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown (ev: KeyboardEvent): void {
    ev.preventDefault();
    const {keyCode, key} = ev;
    if (keyCode === 40) {
      this.setState({ean: ''});
    } else if (keyCode === 13) {
      if (this.state.ean !== '') {
        let ean = this.state.ean;
        // Check if it is an ean code
        this.setState({ean: ''});
        if (ean.length === 13) {
          this.setState({redirect: ('/produkt/' + ean)})
          document.removeEventListener('keydown', this.handleKeyDown, false);
        } else {
          if (this.state.toasts.length === 0) {
            // @ts-ignore
            this.state.toasts.push(new Toast());
          }
        }
      }
    } else if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
      this.setState({ean: this.state.ean + key});
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect push to={this.state.redirect}/>
    }
    let toastElements: any;
    toastElements = this.state.toasts.map((value: any, key: number) => {
      return <Toast key={key}/>
    });
    return (
    <main id="content" className="with-footer">
      <div id="start" className="page level-1">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <div className="barcode-image">
                <img src={ProductscanImage} alt={"Produkt scannen"}/>
              </div>
            </div>
            <div className="col-8 d-flex align-items-center">
              <div className="box box-gray arrow arrow-down flex-2 scanbox">
                <h1 className={"text-berger-blue"}>Barcode scannen</h1>
                <p>Bitte lesen Sie den Barcode am gewünschten Produkt mithilfe des Scanners ein. Der Scanner befindet sich
                  am unteren Ende des Bildschirms. Halten Sie das Produkt einige Sekunden unter den Scanner bis ein
                  Signalton erklingt.</p>
              </div>
            </div>
          </div>
        </div>
        {toastElements}
      </div>
      <Footer />
    </main>
    );
  }
}

interface IState {
  ean: string
  redirect: string | null
  toasts: Toast[]
}
