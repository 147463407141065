import * as React from "react";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Thumbnail from "../images/Bild_Seite1.jpg";

export class Vorteilskarte extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.props.toggleShowBack(true);
    this.props.toggleHideHome(false);
  }
  render() {
    return (
      <main id="content" className="with-footer">
        <div id="start" className="page level-1">
          <div className="container">
            <div className="row align-items-center h-100">
              <div className="col-8">
                <div className="row margin-bottom-2">
                  <div className="col">
                    <h1 className={"text-berger-blue mb-5"}>Ihre gratis Berger <span className="text-berger-red">Vorteils</span>karte</h1>
                  </div>
                  <div className="col d-flex justify-content-center align-items-center">
                    <p className="blockquote">Jetzt beantragen!</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex align-items-end">
                    <div className="box box-gray arrow arrow-up vorteilsbox">
                      <h3 className={"text-berger-blue mt-3"}>Ihre Vorteile:</h3>
                      <ul className="list-unstyled">
                        <li className="vorteil">
                          <p><b>5% Bonus</b><br/>
                            ab einem Jahresumsatz von <b>1.000 €</b>.</p>
                        </li>
                        <li className="vorteil">
                          <p><b>3% Bonus</b><br/>
                            ab einem Jahresumsatz von <b>100 €</b>.</p>
                        </li>
                        <li className="vorteil">
                          <p><b>100 Tage Rückgaberecht</b></p>
                        </li>
                        <li className="vorteil">
                          <p><b>Umtausch ohne Kassenbon</b></p>
                        </li>
                        <li className="vorteil">
                          <p><b>Campingplatzvorteile</b></p>
                        </li>
                        <li className="vorteil">
                          <p>und viele weitere Vorteile!</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col d-flex flex-column justify-content-between">
                    <Link to={'/vorteilskarte/kunde'} className={"btn next-step"}
                          onClick={() => {
                            this.props.toggleShowBack(true);
                            this.props.toggleIsForm(true);
                          }}>
                      Ich bin bereits Kunde
                      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                    </Link>
                    <Link to={'/vorteilskarte/scan'} className={"btn next-step"}
                          onClick={() => {
                            this.props.toggleShowBack(true);
                            this.props.toggleIsForm(true);
                          }}>
                      Ich bin Neukunde
                      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 justify-content-end d-flex flex-column h-100">
                <img src={Thumbnail} className="teaser-image" alt="Berger Kundenkarte"/>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container h-100">
            <ul className="row list-unstyled justify-content-between align-items-center h-100">
              <li className="features">Bis zu 5% Bonus</li>
              <li className="features">100 Tage Rückgaberecht</li>
              <li className="features">Umtausch ohne Kassenbon</li>
              <li className="features">Campingplatzvorteile</li>
            </ul>
          </div>
        </footer>
      </main>
    );
  };
}

interface IProps {
  toggleShowBack: (newShowBackState: boolean) => any
  toggleIsForm: (newIsFormState: boolean) => any
  toggleHideHome: (newHideHomeState: boolean) => any
}