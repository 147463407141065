import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class ReviewsBlock extends React.Component <IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      starsPossible: 5,
      emptyColor: '#6c757d',
      fillColor: '#ffc107'
    }
  }
  render() {
    const percentage = this.props.rating % 1;
    let stars = [];
    let ratingDetails = null;
    let reviewDetails;
    for (let i: number = 0; i < this.state.starsPossible; i++) {
      if (i < Math.floor(this.props.rating) &&  this.props.reviews > 0) {
        stars.push(<FontAwesomeIcon icon={['fas', 'star']} className="text-warning" key={i}/>);
      } else if (i === Math.floor(this.props.rating) && this.props.reviews > 0) {
        stars.push(<FontAwesomeIcon icon={['fas', 'star']} className="ratingstars" key={i}/>);
      } else {
        stars.push(<FontAwesomeIcon icon={['fas', 'star']} className="text-secondary" key={i}/>);
      }
    }
    if (this.props.rating) {
      ratingDetails =
        <div className="row">
          <div className="col">
            <span className="font-weight-bold">{this.props.rating} / {this.state.starsPossible}</span>
          </div>
        </div>;
    }
    if (this.props.short) {
      reviewDetails =
        <div>
          <div className="row">
            <div className="col">
              {stars}
              <span className={"ml-2"}>({this.props.reviews})</span>
            </div>
          </div>
        </div>;
    } else {
      reviewDetails =
        <div>
          <div className="row">
            <div className="col">
              {stars}
            </div>
          </div>
          {ratingDetails}
          <div className="row">
            <div className="col">
              <span>{this.props.reviews} Bewertungen</span>
            </div>
          </div>
        </div>;
    }
    return (
      <div className={this.props.short ? "rating rating-short" : "rating pr-3"}>
        <svg width="0" height="0" className="d-block">
          <linearGradient id="rg" x1="0" y1="0" x2="1" y2="0">
            <stop stopColor={this.state.fillColor} offset={percentage} />
            <stop stopColor={this.state.emptyColor} offset="0" />
          </linearGradient>
        </svg>
        {reviewDetails}
      </div>
    );
  }
}

interface IProps {
  rating: number,
  reviews: number,
  short: boolean
}

interface IState {
  starsPossible: number,
  emptyColor: string,
  fillColor: string
}