import * as React from 'react';
import ImageVorteilskarte from '../images/Bild_Seite3.jpg';
import {Redirect} from "react-router";

export class Danke extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.props.toggleShowBack(false);
    this.state = {
      redirect: false,
    }
  }

  componentDidMount() {
    setTimeout(() => this.setState({redirect: true}), 10000);
  }

  componentWillUnmount() {
    clearTimeout();
  }

  render() {
    return this.state.redirect
      ? <Redirect to={'/'} />
      : (
      <main id="content" className={"with-footer"}>
        <div id="danke" className="page level-3">
          <div className="container-fluid">
            <div className="row flex-grow-1">
              <div className="col-8 text-center">
                <img src={ImageVorteilskarte} className="danke-image" alt={"Vorteilskarte"} />
              </div>
              <div className="col-4 pr-0">
                <div className="box box-gray arrow arrow-left arrow-top">
                  <h1 className={"text-berger-blue"}>Vielen Dank für Ihre Registrierung!</h1>
                  <p>Sie können Ihre Vorteilskarte nun direkt nutzen und von vielen exklusiven Vorteilen profitieren!</p>
                  <h3 className={"text-berger-blue"}>Noch Fragen?</h3>
                  <p>Bei allen Fragen rund um das Thema der Berger Vorteilskarte können Sie sich natürlich gerne an unsere
                    Mitarbeiter vor Ort wenden.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container h-100">
            <ul className="row list-unstyled justify-content-between align-items-center h-100">
              <li className="features">Bis zu 5% Bonus</li>
              <li className="features">100 Tage Rückgaberecht</li>
              <li className="features">Umtausch ohne Kassenbon</li>
              <li className="features">Campingplatzvorteile</li>
            </ul>
          </div>
        </footer>
      </main>
    );
  }
}

interface IProps {
  toggleShowBack: (newShowBackState: boolean) => any
}

interface IState {
  redirect: boolean
}