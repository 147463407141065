import * as React from 'react';
import {CrosssellItem} from "./CrosssellItem";

export class CrosssellList extends React.Component <IProps, any> {
  render() {
    const xsellItems = this.props.artikels.map((artikel: any) => {
      return <CrosssellItem key={artikel.ean} artikel={artikel} />;
    });
    return (
      <div className="xsell d-flex flex-column">
        <div className="xsell-list py-3">
          {xsellItems}
        </div>
      </div>
    );
  }
}

interface IProps {
  artikels: any,
}