import * as React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconName} from "@fortawesome/fontawesome-svg-core";
import '../fontawesome';
import BergerLogo from '../images/Logo.svg';
import {NavIcon} from "./NavIcon";
import {LinkBack} from "./LinkBack";
import {ClearForm} from "./ClearForm";

export class Header extends React.Component<IProps> {
  render() {
    return (
      <header>
        <div className="container underline">
          <div className="row">
            <div id="nav">
              <NavIcon id={'home'} classNames={'icon link-home'} linkTo={'/'} linkText={'Startseite'}
                       faIconName={'home' as IconName} hide={this.props.isHome}
                       toggleIsHome={this.props.toggleIsHome.bind(this)}
              />
              <LinkBack id={"back"} classNames={"icon link-back"} linkText={"Zurück"} faIconName={"chevron-left"}
                        hide={this.props.showBack} toggleShowBack={this.props.toggleShowBack.bind(this)}
                        toggleIsForm={this.props.toggleIsForm.bind(this)} isHome={this.props.isHome}/>
              <ClearForm id={"form-clear"} classNames={"icon clear-input"} linkText={"Eingaben löschen"}
                         faIconName={"times"} hide={this.props.isForm}/>
            </div>
            <div id="logo">
              <span>
                <img src={BergerLogo} alt="Fritz Berger" width="300"/>
              </span>
            </div>
            <div id="link-to-info">
              <div className="icon" onClick={() => this.props.toggleShowModal(true)}>
                <span className="icon-wrapper-circle">
                  <FontAwesomeIcon icon={['fas', 'info']}/>
                </span>
                Teilnahmebedingungen<br/>Datenschutzerklärung
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

interface IProps {
  isHome: boolean,
  showBack: boolean,
  isForm: boolean,
  toggleIsHome: (newIsHomeState: boolean) => any,
  toggleShowBack: (newShowBackState: boolean) => any,
  toggleIsForm: (newIsFormState: boolean) => any,
  toggleShowModal: (newIsFormState: boolean) => any,
}
