import * as React from "react";
import {Link} from "react-router-dom";
import {PriceBlock} from "../components/PriceBlock";
import {MediaBlock} from "../components/MediaBlock";
import {Highlights} from "../components/Highlights";
import {ReviewsBlock} from "../components/ReviewsBlock";
import {CrosssellList} from "../components/CrosssellList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toast} from "../components/Toast";

export class Produkt extends React.Component<IProps, IState>{
  constructor(props: any) {
    super(props);
    this.props.toggleShowBack(true);
    this.state = {
      isLoaded: false,
      item: null,
      ean: '',
      redirect: null,
      toasts: [],
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    this.getArtikel(this.props.match.params.id);
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

   componentDidUpdate() {
    if (this.state.redirect
      && this.state.redirect === this.props.match.params.id) {
      this.getArtikel(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown (ev: KeyboardEvent): void {
    ev.preventDefault();
    const {keyCode, key} = ev;
    if (keyCode === 40) {
      this.setState({ean: ''});
    } else if (keyCode === 13) {
      if (this.state.ean !== '') {
        let ean = this.state.ean;
        // Check if it is an ean code
        this.setState({ean: ''});
        if (ean.length === 13 &&
          this.state.item && !(this.state.item.ean === ean || this.state.item.artikelnr === ean)
        ) {
          this.setState({
            redirect: ean,
            isLoaded: false,
            item: null,
            toasts: [],
          });
          this.props.history.push('/produkt/' + ean);
        } else if (ean.length != 13 && this.state.toasts.length === 0) {
          // @ts-ignore
          this.state.toasts.push(new Toast());
        }
      }
    } else if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
      this.setState({ean: this.state.ean + key});
    }
  }

  async getArtikel(artikelId: string) {
    if (artikelId.length === 13) {
      await fetch("https://info.berger-data.de/api/v1/artikel/" + artikelId)
        .then(res => res.json())
        .then(
          (result) => {
            let artikel: Artikel = result;
            this.setState({isLoaded: true, item: artikel, redirect: null});
          },
          () => {
            this.setState({isLoaded: true, item: null, redirect: null});
          }
        );
    } else if (artikelId.length >= 6 && artikelId.length < 13) {
      await fetch("https://info.berger-data.de/api/v1/artikelnr/" + artikelId)
        .then(res => res.json())
        .then(
          (result) => {
            let artikel: Artikel = result;
            this.setState({isLoaded: true, item: artikel, redirect: null});
          },
          () => {
            this.setState({isLoaded: true, item: null, redirect:null});
          }
        );
    } else {
      this.setState({isLoaded: true, item: null, redirect: null});
    }
  }


  render() {
    const {isLoaded, item} = this.state;
    if (isLoaded) {
      if (item) {
        const brand = item.brand[0];
        let brandElement: JSX.Element | null;
        if (brand) {
          brandElement = <div className="row">
            <div className="col-9">
              <h1 className={"h3"}>{item.title}</h1>
              <p>Artikelnummer: {item.artikelnr}</p>
            </div>
            <div className="col-3">
              <img src={brand.logo} alt={brand.name} className="img-fluid"/>
            </div>
          </div>
        } else {
          brandElement = <div className={"row"}>
            <div className="col-12">
              <h1 className={"h3"}>{item.title}</h1>
              <p>Artikelnummer: {item.artikelnr}</p>
            </div>
          </div>
        }
        const features = item.features.map((feature: any) => {
          return <li key={feature.key}>
            <div className="row">
              <div className="col-5">{feature.key}</div>
              <div className="col-7">{feature.value}</div>
            </div>
          </li>
        });
        let xsell: JSX.Element | null;
        let xsellHeader: JSX.Element | null;
        if (item.xsells.length > 0) {
          xsell = <CrosssellList artikels={item.xsells}/>;
          xsellHeader = <li className="nav-item" role="presentation">
              <a className="nav-link" id="crosssell-tab" data-toggle="tab" href="#crosssell" role="tab"
                 aria-controls="crosssell" aria-selected="true">Zubehör</a>
            </li>
        } else {
          xsell = null;
          xsellHeader = null;
        }
        let toastElements: any;
        toastElements = this.state.toasts.map((value: any, key: number) => {
          return <Toast key={key}/>
        });
        return (
          <main id="content">
            <div id="start" className="page level-1">
              <div className="container">
                <div className="row w-100 h-100 py-5">
                  <div className="col-4 h-100">
                    {brandElement}
                    <MediaBlock images={item.images} video={item.video}/>
                  </div>
                  <div className="col-4 h-100 d-flex flex-column">
                    <div className="row">
                      <div className="col">
                        <div className="bg-light">
                          <div className="row align-items-center py-3">
                            <div className="col border-right border-secondary">
                              <PriceBlock oldPrice={item.oldPrice} currentPrice={item.price} short={false}/>
                            </div>
                            <div className="col">
                              <ReviewsBlock rating={item.rating} reviews={item.reviews} short={false}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Highlights highlights={item.highlights}/>
                    <p className="h5 text-primary">Eigenschaften</p>
                    <div className="d-flex flex-column overflowy-scroll">
                      <ul className="list-striped pb-5">{features}</ul>
                    </div>
                    <div className="overflowy-overlay" />
                  </div>
                  <div className="col-4 h-100 d-flex flex-column">
                    <ul className="nav nav-tabs flex-shrink-0" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="description-tab" data-toggle="tab" href="#description"
                           role="tab" aria-controls="description" aria-selected="false">Beschreibung</a>
                      </li>
                      {xsellHeader}
                    </ul>
                    <div className="tab-content d-flex flex-column overflowy-scroll" id="myTabContent">
                      <div className="tab-pane fade py-2 show active" id="description" role="tabpanel"
                           aria-labelledby="description-tab">
                        <div dangerouslySetInnerHTML={{__html: item.description}}/>
                      </div>
                      <div className="tab-pane fade py-2" id="crosssell" role="tabpanel"
                           aria-labelledby="crosssell-tab">
                        {xsell}
                      </div>
                      <div className="overflowy-overlay" />
                    </div>
                  </div>
                </div>
              </div>
              {toastElements}
            </div>
          </main>
        )
      } else {
        return (
          <main id="content">
            <div id="start" className="page level-1">
              <div className="container">
                <div>
                  <h1 className={"text-primary"}>Uups..., es konnte leider nichts gefunden werden.</h1>
                  <div className={"text-center"}>
                    <Link to="/produkt" className={"btn btn-lg btn-primary"}>Zurück zum Scannen</Link>
                  </div>
                  <div className={"text-center mt-3"}>
                    <Link to="/produktsuche" className={"btn btn-lg btn-primary"}>Zurück zur Artikelnummereingabe</Link>
                  </div>
                </div>
              </div>
            </div>
          </main>
        );
      }
    } else {
      return (
        <main id="content">
          <div id="start" className="page level-1">
            <div className="container">
              <div>
                <h1 className={"text-primary"}>Loading</h1>
                <div className={"text-center"}>
                  <FontAwesomeIcon icon={["fas","spinner"]} size={"6x"} className={"text-primary"} spin/>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
  }
}

interface IProps {
  match: any
  history: any
  toggleShowBack: (newShowBackState: boolean) => any
}

interface IState {
  isLoaded: boolean
  item: Artikel | null
  ean: string
  redirect: string | null
  toasts: Toast[]
}

interface Artikel {
  ean: string,
  artikelnr: string,
  title: string,
  brand: any,
  images: string,
  video: string,
  description: string,
  features: any,
  price: number,
  oldPrice: number,
  highlights: any,
  rating: number,
  reviews: number,
  xsells: any,
}