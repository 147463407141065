import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Header} from "../components/Header";
import {Start} from "./Start";
import {Produkt} from "./Produkt";
import {Productscan} from "./Productscan";
import {Produktsuche} from "./Produktsuche";
import {Vorteilskarte} from "./Vorteilskarte";
import {Vorteilskartescan} from "./Vorteilskartescan";
import {Kunde} from "./Kunde";
import {Neukunde} from "./Neukunde";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Danke} from "./Danke";

export const AppContext = React.createContext<IAppContext | null>(null);

export class App extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = {
      isHome: true,
      showBack: false,
      isForm: false,
      showModal: false,
    };
  }

  toggleIsHome(newState: boolean) {
    this.setState({isHome: newState});
    this.setState({showBack: false});
    this.setState({isForm: false});
  }

  toggleShowBack(newState: boolean) {
    this.setState({showBack: newState});
  }

  toggleHideHome(newState: boolean) {
    this.setState({isHome: newState})
  }

  toggleIsForm(newState: boolean) {
    this.setState({isForm: newState});
  }

  toggleShowModal(newState: boolean) {
    this.setState({showModal: newState});
  }

  setShowModal = (newState: boolean) => {
    this.setState({
      showModal: newState
    });
  };

  render() {
    const appContext: IAppContext = {
      ...this.state,
      setShowModal: this.setShowModal
    };
    return (
      <AppContext.Provider value={appContext}>
        <BrowserRouter>
          <Header isHome={this.state.isHome} showBack={this.state.showBack} isForm={this.state.isForm}
                  toggleIsHome={this.toggleIsHome.bind(this)}
                  toggleShowBack={this.toggleShowBack.bind(this)}
                  toggleIsForm={this.toggleIsForm.bind(this)}
                  toggleShowModal={this.toggleShowModal.bind(this)}
          />
          <Switch>
            <Route path="/" exact
                   render={(props) =>
                     <Start {...props}
                            toggleIsHome={this.toggleIsHome.bind(this)}
                     />
                   }/>
            <Route path="/produktsuche" exact
                   render={(props) =>
                     <Produktsuche {...props}
                            toggleIsHome={this.toggleIsHome.bind(this)}
                     />
                   }/>
            <Route path="/produkt/:id"
                   render={(props) =>
                     <Produkt {...props}
                              toggleShowBack={this.toggleShowBack.bind(this)}
                     />
                   }/>
            <Route path="/produkt" component={Productscan}/>
            <Route path="/vorteilskarte" exact
                   render={(props) =>
                     <Vorteilskarte {...props}
                                    toggleShowBack={this.toggleShowBack.bind(this)}
                                    toggleIsForm={this.toggleIsForm.bind(this)}
                                    toggleHideHome={this.toggleHideHome.bind(this)}
                     />
                   }/>
            <Route path="/vorteilskarte/kunde"
                   render={(props) =>
                     <Kunde {...props}
                            toggleShowBack={this.toggleShowBack.bind(this)}
                            toggleIsForm={this.toggleIsForm.bind(this)}
                            toggleHideHome={this.toggleHideHome.bind(this)}
                     />
                   }/>
            <Route path="/vorteilskarte/neukunde/:id"
                   render={(props) =>
                     <Neukunde {...props}
                               toggleShowBack={this.toggleShowBack.bind(this)}
                               toggleIsForm={this.toggleIsForm.bind(this)}
                               toggleHideHome={this.toggleHideHome.bind(this)}
                     />
                   }/>
            <Route path="/vorteilskarte/scan"
                   render={(props) =>
                     <Vorteilskartescan {...props}
                               toggleShowBack={this.toggleShowBack.bind(this)}
                               toggleIsForm={this.toggleIsForm.bind(this)}
                               toggleHideHome={this.toggleHideHome.bind(this)}
                     />
                   }/>
            <Route path="/danke"
                   render={props =>
                     <Danke {...props}
                            toggleShowBack={this.toggleShowBack.bind(this)}
                     />
                   }/>
          </Switch>

          <div id="modal" className={"modal fade" + (this.state.showModal ? " show" : "")}>
            <div id="close-modal" onClick={() => this.toggleShowModal(false)}>
              <FontAwesomeIcon icon={['fas', 'times']}/>
            </div>
            <div className="modal-inner">
              <h1>Teilnahmebedingungen</h1>
              <p><strong>Teilnahmevoraussetzungen / Herausgeber</strong></p>
              <p>Betreiberin ist die Fritz Berger GmbH, Fritz-Berger-Str. 1, 92318 Neumarkt, Deutschland, (im Folgenden
                Fritz Berger). Am Fritz Berger Vorteilskarten-Bonusprogramm kann jeder Verbraucher, der das 18.
                Lebensjahr
                vollendet hat, teilnehmen. Ausgeschlossen sind Mitarbeiter der Fritz Berger GmbH und Unternehmer. Gemäß
                §
                13 BGB ist Verbraucher jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die
                überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden
                können. Unternehmer ist gemäß § 14 BGB eine natürliche oder juristische Person oder eine rechtsfähige
                Personengesellschaft, die bei Abschluss eines Rechtsgeschäfts in Ausübung ihrer gewerblichen oder
                selbständigen beruflichen Tätigkeit handelt. Mit dem Antrag auf Registrierung als Teilnehmer erkennen
                Sie
                diese allgemeinen Teilnahmebedingungen an. Dabei muss das Antragsformular in den Pflichtfeldbereichen
                vollständig ausgefüllt, unterschrieben und in einer Fritz Berger Filiale abgegeben werden. Online ist
                eine
                Registrierung unter www.Fritz-Berger.de möglich. Nach positiver Prüfung Ihres Teilnahmeantrages senden
                wir
                Ihnen Ihre Fritz Berger-Vorteilskarte per Post zu oder übergeben diese direkt mit diesem Antrag in der
                Filiale. Die Vorteilskarte ist ausschließlich für Sie selbst bestimmt und nicht übertragbar. Ein
                Anspruch
                auf Teilnahme am Bonusprogramm besteht nicht.</p>
              <p><strong>Sammeln und Einlösen des Bonus</strong></p>
              <p>Zur Durchführung der Teilnahmebedingungen und Ihrer Teilnahme am Fritz
                Berger-Bonusprogramm ist es deshalb erforderlich, dass Fritz Berger Ihre personenbezogenen Daten zu
                Ihren
                Einkäufen (Umsätze) bei Fritz Berger, auch die im Onlineshop (www.fritz-berger.de), erhebt und
                auswertet.
                Bei jedem Einkauf bei Fritz Berger (Onlinebestellung auf www.fritz-berger.de, Fritz Berger Filialen
                sowie
                schriftliche und telefonische Bestellungen aus dem Fritz Berger Katalog) wird der Warenwert Ihres
                Einkaufes (berechneter Verkaufspreis der gekauften Artikel nach Abzug ggf. gewährter Rabatte, ohne
                Versandkosten) Ihrem Vorteilskarten-Bonuskonto gutgeschrieben. Ausgenommen vom Bonusprogramm sind
                Einkäufe
                über Vertriebsplattformen, die nicht von Fritz Berger betrieben werden, wie z.B. Amazon oder Ebay sowie
                Werkstattumsätze bei Partnerfirmen. Beim Einkauf in der Filiale ist das vorzeigen Ihrer Vorteilskarte
                erforderlich. Ausgenommen hiervon ist der Kauf von Geschenkgutscheinen. Wird ein Kaufvertrag ganz oder
                teilweise rückabgewickelt, wird der rückabgewickelte Warenwert Ihrem Vorteilskarten-Bonuskonto wieder
                belastet. Über die aktuelle Höhe Ihres Umsatzes erhalten Sie jederzeit Auskunft über die Hotline
                09181/330-100, im Internet unter www.fritz-berger.de bei „Mein Konto“ oder in jeder Fritz Berger
                Filiale.
                Bis 99,99 € Jahresumsatz -&gt; kein Bonus, ab 100,00 € Jahresumsatz -&gt; 3% vom Jahresumsatz, ab
                1.000,00
                € Jahresumsatz -&gt; 5% vom Jahresumsatz. Beispiel: Ihr Jahresumsatz: 1.749,95 € -&gt; Ihr
                Bonus-Gutschein: 87,50 €. Über den Ihnen zustehenden Bonus wird ein Gutschein erstellt und Ihnen an Ihre
                Adresse bis Mitte März des Folgejahres zugesandt. Der Bonus-Gutschein hat eine Gültigkeit bis 31.12.
                desselben Jahres, in dem Ihnen der Gutschein zugegangen ist. Der Gutschein kann jederzeit beim Einkauf
                bei
                Fritz Berger (Onlinebestellung auf www.fritz-berger.de, Fritz Berger Filialen sowie schriftliche und
                telefonische Bestellungen aus dem Fritz Berger Katalog) eingelöst werden. Ein Anspruch auf Barauszahlung
                des Bonus-/Gutscheinbetrages besteht nicht. Sollten Sie Einwände gegenüber unserer Abrechnung geltend
                machen wollen, senden Sie uns diese bitte schriftlich unter Beifügung der betreffenden
                Rechnungskopien/Kassenzettel und des Originalgutscheins innerhalb von 3 Monaten ab Erhalt der
                Bonusabrechnung/des Gutscheines ein. Hierauf werden Sie in der Bonusabrechnung nochmal gesondert
                hingewiesen. Verspätete Einwände können wir leider nicht anerkennen.</p>
              <p><strong>Kündigung/Beendigung der Teilnahme / Änderung Bonusprogramm</strong></p>
              <p>Sie können die Teilnahme am Fritz Berger Vorteilskarten-Bonusprogramm jederzeit ohne Einhaltung einer
                Frist wie folgt kündigen: Durch schriftliche Mitteilung auf dem Postweg an: Fritz Berger GmbH,
                Fritz-Berger-Str. 1, 92318 Neumarkt, Deutschland. Durch Mitteilung per E-Mail an
                service@fritz-berger.de.
                Außerdem ist die Fritz Berger GmbH berechtigt, das Bonusprogramm jederzeit teilweise oder vollständig
                einzustellen. Darüber hinaus behält sich Fritz Berger vor, diese allgemeinen Teilnahmebedingungen zu
                ändern. Über die Änderungen erhalten Sie unter Ihrer registrierten Anschrift vorab eine schriftliche
                Mitteilung. Widersprechen Sie nicht innerhalb von einem Monat schriftlich gegenüber der Fritz Berger
                GmbH,
                gelten die Änderungen als genehmigt. Als Genehmigung gilt auch, wenn Sie sich nach Erhalt der geänderten
                Bedingungen einen neuen Umsatz gutschreiben lassen. Auf diese Rechtsfolgen werden Sie in der
                schriftlichen
                Mitteilung der Änderungen nochmals ausdrücklich hingewiesen. Es steht Ihnen selbstverständlich frei,
                Ihre
                Teilnahme am Bonus&shy;programm jederzeit zu beenden.</p>
              <p><strong>Verlust Bonuskarte</strong></p>
              <p>Sollten Sie die Vorteilskarte verlieren bzw. wurde Ihnen die Vorteilskarte gestohlen, sind Sie
                verpflichtet, uns unverzüglich darüber zu informieren. In diesem Fall können Sie unentgeltlich eine
                Ersatzkarte beantragen.</p>
              <h1>Datenschutzerklärung Fritz Berger Vorteilskarte-Bonusprogramm</h1>
              <p>Wir nehmen den Datenschutz sehr ernst und das gilt selbstverständlich auch in Bezug auf die
                personenbezogenen Daten unserer Fritz Berger Vorteilskarten-Bonusprogramm Mitglieder („Daten“).
                Wir informieren Sie hiermit, wie wir Ihre Daten verarbeiten und welche Ansprüche und Rechte Ihnen nach
                den
                datenschutzrechtlichen Regelungen zustehen. Gültig ab 25. Mai 2018.</p>
              <p><strong>• Wichtige Kontaktdaten zu uns als verantwortliche Stelle:</strong><br/>
                <strong>Fritz Berger GmbH<br/>
                  Fritz-Berger-Str. 1<br/>
                  92318 Neumarkt<br/>
                  Telefon: 09181/330 - 0<br/>
                  Fax: 09181/330 - 159<br/>
                  E-Mail: info@fritz-berger.de</strong></p>
              <p><strong>Unter den vorstehenden Kontaktdaten erreichen Sie auch den von uns bestellten
                Datenschutzbeauftragten Herrn Karl Berschneider, im Übrigen auch per E-Mail: datenschutz@fritz-berger.de
              </strong></p>
              <p><strong>• Kategorien von Daten:</strong><br/>
                Wir verarbeiten insbesondere die folgenden Kategorien von personenbezogenen Daten
                über Sie: Fritz Berger Vorteilskarte Registrierungsdaten, Daten zu Einkäufen und Umsätzen. Diese Daten
                stellen Sie uns direkt zur Verfügung (z. B. im Rahmen der Registrierung oder bei einem Einkauf).</p>
              <p><strong>• Verarbeitungszwecke:</strong><br/>
                Die vorgenannten personenbezogenen Daten nutzen wir ausschließlich zur Abwicklung des Fritz Berger
                Bonuskarten-Programms, zur Pflege der Kundenbeziehung, zur Marktforschung sowie für unsere
                Werbeansprachen.</p>
              <p><strong>• Rechtsgrundlage:</strong><br/>
                Die Verarbeitung Ihrer Daten ist zur Durchführung des Fritz Berger
                Vorteilskarte-Vertrages mit Ihnen erforderlich und erfolgt auf Grundlage von Art. 6 Abs. 1 Satz 1 lit.
                b) DSGVO. Mit der Datenverarbeitung zur Pflege der Kundenbeziehung, zur Marktforschung, für unsere
                Werbeansprachen sowie dem Einsatz von Cookies auf unseren Internetseiten verfolgen wir unsere
                berechtigten Interessen auf Grundlage von Art. 6 Abs. 1 Satz 1 lit. f) DSGVO. Soweit wir Ihre Daten
                auf Grundlage einer Einwilligung verarbeiten (z.B. Newsletter) erfolgt dies gemäß Art. Art. 6 Abs. 1
                Satz 1 lit. a) DSGVO.</p>
              <p><strong>• Kategorien von Empfängern Ihrer Daten:</strong><br/>
                Neben den verantwortlichen Stellen haben gegebenenfalls externe Dienstleister, die uns bei der
                Erbringung
                unserer Dienstleistungen unterstützen, Zugriff auf Ihre Daten. Vereinzelt können sonstige Dritte wie
                Behörden, externe Berater oder bestimmte Geschäftspartner Ihre Daten erhalten. Ihre Daten werden
                nicht außerhalb der EU verarbeitet.</p>
              <p><strong>• Dauer der Speicherung Ihrer Daten:</strong><br/>
                Wir verarbeiten und speichern Ihre Daten unter Berücksichtigung gesetzlicher Aufbewahrungspflichten
                für die Dauer unserer Vertragsbeziehung und soweit wir diese auch zur werblichen Ansprache nutzen
                bis Sie Ihre Einwilligung hierzu widerrufen und/oder unserer Werbung widersprechen.</p>
              <p><strong>• Ihre Datenschutzrechte, insb. Widerspruchsrecht:</strong><br/>
                Sie haben das Recht auf Auskunft über Ihrer bei uns gespeicherten Daten und die diesbetreffenden
                Verarbeitungen nach Art. 15 DSGVO, das Recht auf Berichtigung Ihrer Daten nach Art. 16 DSGVO, das Recht
                auf Löschung Ihrer Daten nach Vertragserfüllung nach Art. 17 DSGVO, soweit dieser keine
                Aufbewahrungspflichten entgegenstehen, das Recht auf Einschränkung der Verarbeitung nach Art. 18 DSGVO,
                das Recht auf Widerspruch nach Art. 21 DSGVO sowie das Recht auf Datenübertragbarkeit nach Art. 20
                DSGVO.
                Sie können sich auch bei einer zuständigen Aufsichtsbehörde für den Datenschutz über uns beschweren,
                wenn
                Sie der Meinung sind, dass wir Ihre Daten nicht im Einklang mit dem geltenden Datenschutzrecht
                verarbeiten.</p>
              <p><strong>Bestehen eines Widerspruchsrecht in besonderen Situationen</strong><br/><br/>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, der Verarbeitung
                Ihrer
                Daten zu widersprechen, die zur Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
                Interesse
                liegt oder in Ausübung öffentlicher Gewalt erfolgt (Art. 6 Abs. 1 Satz 1 lit. e), welche uns übertragen
                wurde. Dies gilt auch für ein auf die vorstehende Bestimmung gestütztes Profiling. Widersprechen Sie der
                Verarbeitung, werden wir Ihre Daten zu diesen Zwecken nicht mehr verarbeiten, es sei denn, wir können
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, echte und
                Freiheiten
                überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen.<br/><br/>
                <strong>Bestehen eines Widerspruchsrecht gegen die Verarbeitung von Daten zur werblichen Ansprache
                  einschließlich damit zusammenhängender vorgelagerter Verarbeitungen (Profiling).</strong><br/><br/>
                Wir verarbeiten Ihre Daten zur werblichen Ansprache und führen hierzu vorab Verarbeitungen durch, um
                unsere Marketingmaßnahmen auf Ihre Interessen auszurichten (Profiling). Diese Verarbeitungen zum Zwecke
                der Direktwerbung erfolgt auf Grundlage eines überwiegenden berechtigten Interesses. Sie können der
                Verarbeitung Ihrer personenbezogenen Daten zum Zwecke der Direktwerbung jederzeit widersprechen. Dies
                gilt
                auch für das Profiling, soweit es mit der werblichen Ansprache in Verbindung steht. Widersprechen Sie
                der
                Verarbeitung für Zwecke der Direktwerbung, so werden Ihre Daten nicht mehr für diese Zwecke verarbeitet.
                Ihr Widerspruch kann ohne Einhaltung einer besonderen Form erfolgen.</p>
              <p><strong>• Freiwilligkeit der Datenzurverfügungstellung:</strong><br/>
                Es besteht keine gesetzliche Pflicht für die Bereitstellung Ihrer Daten an Fritz Berger. Allerdings
                können
                Sie nicht am Fritz Berger Vorteilskarten-Bonusprogramm teilnehmen, wenn Sie uns Ihre Daten nicht zur
                Verfügung stellen. Die Bereitstellung Ihrer Daten ist vollkommen freiwillig. Wenn Sie Ihre Daten nicht
                zur
                Verfügung stellen, erleiden Sie zwar keine rechtlichen Nachteile, jedoch können Sie dann die Vorzüge und
                Leistungen des Fritz Berger Bonusprogramm nicht genießen.<br/><br/>
                <strong>Datenschutzrechtliche Information im Einzelnen</strong><br/>
                <strong>1. Für die Datenverarbeitung verantwortliche Stellen und Kontaktdaten</strong><br/><br/>
                Verantwortlicher für die Datenverarbeitung im Sinne der Datenschutz-Grundverordnung Art. 26 (DSGVO) ist
                Fritz Berger GmbH, Fritz-Berger-Str. 1,92318 Neumarkt, Deutschland, Tel.: 09181/330-0, E-Mail:
                info@fritz-berger.de. Der für die Verarbeitung von personenbezogenen Daten Verantwortliche ist diejenige
                natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
                Verarbeitung von personenbezogenen Daten entscheidet.<br/>
                Das Fritz Berger Vorteilskarten-Bonusprogramm ist ein Kundenbindungsprogramm für die gesamten Fritz
                Berger
                Angebote und Dienstleistungen.<br/>
                <u>Datenschutzbeauftragter:</u> Der Verantwortliche hat einen Datenschutzbeauftragten bestellt, dieser
                ist
                wie folgt zu erreichen: Fritz-Berger-Str. 1, 92318 Neumarkt, 091 81/330-0,
                datenschutz@fritz-berger.de<br/><br/>
                Teilweise stellen Sie diese Daten direkt zur Verfügung (z. B. im Rahmen der Registrierung oder bei einem
                Einkauf) und teilweise erheben wir diese Daten automatisch (z. B. beim Besuch unseres Online-Shops).</p>
              <table>
                <tbody>
                <tr>
                  <th>Datenkategorie</th>
                  <th>Beispiele</th>
                </tr>
                <tr>
                  <td>Fritz Berger Vorteilskarte Registrierungsdaten</td>
                  <td>Name, Geschlecht, postalische Adresse, Geburtsdatum, E-Mail-Adresse,
                    Telefonnummer (soweit angegeben) Fritz Berger Kundennummer, Datum der
                    Registrierung
                  </td>
                </tr>
                <tr>
                  <td>Einkäufe und Umsätze</td>
                  <td>Details zu Einkäufen bei Fritz Berger, insbesondere Kanal, Zeitpunkt,
                    Produkt, Einkaufswert, Rückgabe von Produkten, eingelöste Gutscheine und
                    Details zur Einlösung
                  </td>
                </tr>
                <tr>
                  <td>Präferenzen zu E-Mail-Werbung</td>
                  <td>Einwilligung in E-Mail-Werbung</td>
                </tr>
                <tr>
                  <td>Präferenzen zu postalischer Werbung</td>
                  <td>Widerspruch zu postalischer Werbung</td>
                </tr>
                </tbody>
              </table>
              <p><strong>2. Verarbeitungszwecke:</strong><br/>
                Wir verarbeiten Ihre personenbezogenen Daten im Einklang mit den Bestimmungen der
                Datenschutz-Grundverordnung (DSGVO), dem Bundesdatenschutzgesetz (BDSG) sowie anderen anwendbaren
                Datenschutzvorschriften zu den folgenden Zwecken:</p>
              <p>• Durchführung des Fritz Berger Vorteilskarten Bonusprogramms, insbesondere<br/>
                • Auswertung Ihres Fritz Berger-Einkaufsverhaltens, der Umsätze und Ihres Besuchs des Online-Shops
                einschließlich des Einsatzes von Cookies und ähnlichen Technologien.<br/>
                • E-Mail-Werbung und postalische Werbung<br/>
                • Persönliche und individuelle Kommunikation und Produktempfehlungen <br/>
                • Einlösen Ihres Bonus in Form eines geldwerten Gutscheins bei einem Ihrer nächsten Einkäufe bei Fritz
                Berger (im Online-Shop auf www.fritz-berger.de, in Fritz Berger Filialen sowie schriftliche und
                telefonische Bestellungen aus dem Fritz Berger Katalog)<br/>
                • Einhaltung gesetzlicher Vorschriften<br/>
                • Geltendmachung, Ausübung und Verteidigung von Rechtsansprüchen</p>
              <p><strong>3. Rechtsgrundlage:</strong><br/>
                Die Verarbeitung Ihrer Daten zur Durchführung des Fritz Berger Vorteilskarten-Bonusprogramms ist
                zulässig, weil dies zur Durchführung des Vertragsverhältnisses mit Ihnen zur Teilnahme am Fritz Berger
                Vorteilskarten-Bonusprogramms erforderlich ist (Art. 6 Abs. 1 Satz 1 lit. b) DSGVO) und weil wir ein
                überwiegendes berechtigtes Interesse haben (Art. 6 Abs. 1 Satz 1 lit. f) DSGVO).</p>
              <p>Unser überwiegendes berechtigtes Interesse begründen wir insbesondere wie folgt: Mit der Teilnahme am
                Fritz Berger Vorteilskarten-Bonusprogramm haben Sie Ihr Interesse an personalisierten Informationen zu
                den
                Produkten und Dienstleistungen von Fritz Berger bekundet.</p>
              <p>Soweit gesetzlich eine Einwilligung erforderlich ist, beispielsweise für bestimmte E-Mail-Werbung oder
                für bestimmte Kategorien von Cookies, holen wir diese Einwilligung ein. Grundlage ist Art. 6 Abs. 1 Satz
                1
                lit. a) DSGVO. Wenn die Einwilligung nicht erteilt wird, führen wir die Datenverarbeitungsprozesse, die
                eine Einwilligung zwingend erfordern, nicht durch.</p>
              <p>Im Übrigen gestatten gesetzliche Erlaubnisnormen, wie steuerrechtliche Aufbewahrungsfristen oder die
                Geltendmachung, Ausübung und Verteidigung von Rechtsansprüchen, die Verarbeitung Ihrer Daten.</p>
              <p><strong>4. Empfänger oder Kategorien von Empfängern Ihrer Daten</strong><br/>
                <strong>4.1 Fritz Berger:</strong> Fritz Berger, als verantwortliche Stelle, hat nur insoweit Zugriff
                auf
                Ihre Daten, wie es zur Erreichung der Zwecke entsprechend der internen Aufgabenverteilung erforderlich
                ist. Hierzu werden intern nur denjenigen Abteilungen und Mitarbeitern Zugriff auf Ihre Daten eingeräumt,
                die den Zugriff tatsächlich benötigen.<br/>
                <strong>4.2 Dienstleister:</strong> Wir haben Dienstleister eingeschaltet, die als Auftragsverarbeiter
                Zugriff auf Ihre Daten haben und diese für von uns konkret festgelegte Zwecke verarbeiten. Diese
                Auftragsverarbeiter können Marketing-Dienstleister, Website-Hosting-Dienstleister,
                IT-Support-Dienstleister oder Website-Analyse-Dienstleister sein.<br/>
                <strong>4.3 Sonstige Dritte:</strong> Unter Umständen geben wir Ihre Daten an Behörden, externe Berater
                oder Gerichte weiter. Soweit wir eine Fritz Berger Filiale durch einen Handelspartner betreiben lassen,
                geben wir Ihre Daten in dem erforderlichen Umfang an diese Handelspartner weiter.</p>
              <p><strong>5. Dauer der Speicherung Ihrer Daten</strong><br/>
                Wir verarbeiten und speichern Ihre Daten im Grundsatz für die Dauer unserer Vertragsbeziehung. Das
                schließt auch die Anbahnung eines Vertrages mit ein (vorvertragliches Rechtsverhältnis).<br/>
                Darüber hinaus haben wir für Ihre Daten verschiedene Aufbewahrungs- und Dokumentationspflichten zu
                beachten, die sich unter anderem aus dem Handelsgesetzbuch (HGB) und der Abgabenordnung (AO) ergeben.
                Die
                dort vorgegebenen Fristen zur Aufbewahrung bzw. Dokumentation betragen bis zu zehn Jahre über das Ende
                der
                Vertragsbeziehung oder des vorvertraglichen Rechtsverhältnisses hinaus.<br/>
                Ferner können spezielle gesetzliche Vorschriften eine längere Aufbewahrungsdauer erfordern wie z.B. die
                Erhaltung von Beweismitteln im Rahmen der gesetzlichen Verjährungsvorschriften. Nach den §§ 195 ff. des
                Bürgerlichen Gesetzbuches (BGB) beträgt die regelmäßige Verjährungsfrist zwar drei Jahre, es können aber
                auch Verjährungsfristen von bis zu 30 Jahren anwendbar sein.<br/>
                Sind die Daten für die Erfüllung vertraglicher oder gesetzlicher Pflichten und Rechte nicht mehr
                erforderlich, werden diese regelmäßig gelöscht, es sei denn, deren – befristete – Weiterverarbeitung ist
                zur Erfüllung der oben aufgeführten Zwecke aus einem überwiegenden berechtigten Interesse erforderlich.
              </p>
              <p><strong>6. Ihre Datenschutzrechte:</strong><br/>
                Sie können uns gegenüber die folgenden Datenschutzrechte geltend machen:</p>
              <p><strong>• Recht auf Widerruf einer erteilten Einwilligung:</strong> Wenn Sie bezüglich bestimmter Arten
                von Verarbeitungstätigkeiten Ihre Einwilligung gegeben haben, können Sie diese Einwilligung jederzeit
                mit
                Wirkung für die Zukunft widerrufen. Dieser Widerruf beeinträchtigt jedoch nicht die Rechtmäßigkeit der
                Verarbeitung vor dem Widerruf Ihrer Einwilligung oder soweit die Verarbeitung über eine andere
                Rechtsgrundlage gerechtfertigt werden kann.</p>
              <p><strong>• Recht auf Auskunft:</strong> Sie haben das Recht, von uns Auskunft über Ihre bei uns
                gespeicherten Daten zu erhalten (Art. 15 DSGVO), ggf. mit Einschränkungen nach § 34 BDSG.</p>
              <p><strong>• Recht zur Berichtigung:</strong> Auf Ihren Wunsch hin werden wir die über Sie gespeicherten
                Daten nach Art. 16 DSGVO berichtigen, wenn diese unzutreffend oder fehlerhaft sind.</p>
              <p><strong>• Recht auf Löschung:</strong> Wenn Sie es wünschen, werden wir Ihre Daten nach den Grundsätzen
                von Art. 17 DSGVO löschen, sofern andere gesetzliche Regelungen (z. B. gesetzliche
                Aufbewahrungspflichten
                oder die Einschränkungen nach § 35 BDSG) oder ein überwiegendes Interesse unsererseits (z. B. zur
                Verteidigung unserer Rechte und Ansprüche) dem nicht entgegenstehen.</p>
              <p><strong>• Recht auf Einschränkung der Bearbeitung:</strong> Unter Berücksichtigung der Voraussetzungen
                des Art. 18 DSGVO können Sie von uns verlangen, die Verarbeitung Ihrer Daten einzuschränken.</p>
              <p><strong>• Recht auf Widerspruch: </strong></p>
              <p><strong>Bestehen eines Widerspruchsrecht in besonderen Situationen</strong></p>
              <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, der Verarbeitung
                Ihrer
                Daten zu widersprechen, die zur Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen
                Interesse
                liegt oder in Ausübung öffentlicher Gewalt erfolgt (Art. 6 Abs. 1 Satz 1 lit. e), welche uns übertragen
                wurde. Dies gilt auch für ein auf die vorstehende Bestimmung gestütztes Profiling. Widersprechen Sie der
                Verarbeitung, werden wir Ihre Daten zu diesen Zwecken nicht mehr verarbeiten, es sei denn, wir können
                zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
                Freiheiten
                überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen.</p>
              <p><strong>Bestehen eines Widerspruchsrecht gegen die Verarbeitung von Daten zur werblichen Ansprache
                Einschließlich damit zusammenhängender vorgelagerter Verarbeitungen (Profiling).</strong></p>
              <p>Wir verarbeiten Ihre Daten zur werblichen Ansprache und führen hierzu vorab Verarbeitungen durch, um
                unsere Marketingmaßnahmen auf Ihre Interessen auszurichten (Profiling). Diese Verarbeitungen zum Zwecke
                der Direktwerbung erfolgt auf Grundlage eines überwiegenden berechtigten Interesses.</p>
              <p>Sie können der Verarbeitung Ihrer personenbezogenen Daten zum Zwecke der Direktwerbung jederzeit
                widersprechen. Dies gilt auch für das Profiling, soweit es mit der werblichen Ansprache in Verbindung
                steht. Widersprechen Sie der Verarbeitung für Zwecke der Direktwerbung, so werden Ihre Daten nicht mehr
                für diese Zwecke verarbeitet.</p>
              <p>Ihr Widerspruch kann ohne Einhaltung einer besonderen Form erfolgen.</p>
              <p><strong>Soweit Sie Ihre Einwilligung für Direktwerbung erteilt haben und diese Direktwerbung nicht mehr
                erhalten wollen, müssen Sie Ihre Einwilligung widerrufen.</strong></p>
              <p><strong>• Recht auf Datenübertragbarkeit:</strong> Auch haben Sie das Recht, Ihre Daten gemäß den
                Regularien von Art. 20 DSGVO in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten
                oder sie einem Dritten zu übermitteln.</p>
              <p><strong>• Beschwerde zur Datenschutzbehörde:</strong> Ferner steht Ihnen ein Beschwerderecht bei jeder
                zuständigen Datenschutzaufsichtsbehörde zu (Art. 77 DSGVO). Wir empfehlen allerdings, eine Beschwerde
                zunächst immer an unseren Datenschutzbeauftragten zu richten, damit wir Ihr Anliegen schnellstmöglich
                und
                kundenorientiert lösen können.</p>
              <p>Für eine zügige Bearbeitung bitten wir Sie, Ihre Anträge über die Ausübung Ihrer Rechte an die
                nachstehende Adresse oder direkt an unseren Datenschutzbeauftragten zu richten:<br/>
                Fritz Berger GmbH<br/>
                - Datenschutzbeauftragter -<br/>
                Fritz-Berger-Str. 1<br/>
                92318 Neumarkt<br/>
                datenschutz@fritz-berger.de</p>
              <p><strong>7. Umfang Ihrer Pflichten, uns Ihre Daten bereitzustellen:</strong><br/>
                Es besteht keine gesetzliche Pflicht für die Bereitstellung Ihrer Daten an Fritz Berger. Allerdings
                können
                Sie nicht am Fritz Berger Vorteilskarten-Bonusprogramm teilnehmen, wenn Sie uns Ihre Daten nicht zur
                Verfügung stellen. Die Bereitstellung Ihrer Daten ist vollkommen freiwillig. Wenn Sie Ihre Daten nicht
                zur
                Verfügung stellen, erleiden Sie zwar keine rechtlichen Nachteile, jedoch können Sie dann die Vorzüge und
                Leistungen des Fritz Berger Vorteilskarten-Bonusprogramm nicht genießen.</p>
              <p><strong>8. Werden automatisierte Entscheidungsfindungen eingesetzt?</strong><br/>
                Sie haben das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung – einschließlich
                Profiling – beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung
                entfaltet oder Sie in ähnlicher Weise erheblich beeinträchtigt (Art. 22 DSGVO). Solche automatisierten
                Entscheidungen setzen wir grundsätzlich nicht ein. Sollten wir ausnahmsweise eine solche Verarbeitung
                durchführen, werden wir Sie hierüber vorab informieren.</p>
              <p><strong>9. Bildung von Profilen (Profiling):</strong><br/>
                Wir verarbeiten Ihre Daten teilweise automatisiert mit dem Ziel, Sie möglichst interessengerecht über
                die
                Leistungen und Produkte der Fritz Berger GmbH zu unterrichten. Hierbei berücksichtigen wir eine
                bestehende
                Geschäftsbeziehung einschließlich der bisher durch den Kunden in Anspruch genommenen Leistungen und
                Produkte sowie den Umfang des Umsatzes.</p>
              <p><strong>10. Sonstiges: </strong><br/>
                Diese datenschutzrechtliche Information wurde zuletzt aktualisiert am 25. Mai 2018. Fritz Berger behält
                sich das Recht vor, diese datenschutzrechtliche Information von Zeit zu Zeit zu aktualisieren.</p>
              <p><strong>Impressum / Anbieterkennzeichnung / Vertragspartner</strong><br/>
                Fritz Berger GmbH, Fritz-Berger-Straße 1, D-92318 Neumarkt <br/>
                Tel. 09181/330-0, Fax 09181/330-159, E-Mail: service@fritz-berger.de <br/>
                Geschäftsführung: Dr. Matthias Herfeld, Maurice Perske, Michael Egbers <br/>
                Sitz der Gesellschaft: Neumarkt <br/>
                Handelsregister: Amtsgericht Nürnberg, Registernummer: HRB 22689 <br/>
                USt-IdNr. DE245320142, WEEE-Reg.-Nr. DE 38864883</p>
            </div>
          </div>
        </BrowserRouter>
      </AppContext.Provider>
    );
  };
}

interface IAppProps {
}

interface IAppState {
  isHome: boolean,
  showBack: boolean,
  isForm: boolean,
  showModal: boolean,
}

interface IAppContext extends IAppState {
  setShowModal: (newState: boolean) => void;
}
