import * as React from 'react';
import {Link} from "react-router-dom";
import {PriceBlock} from "./PriceBlock";
import {ReviewsBlock} from "./ReviewsBlock";

export class CrosssellItem extends React.Component <IProps, any> {

  render() {
    return (
      <Link to={"/produkt/" + this.props.artikel.ean} className="d-block border mb-3 p-1">
        <div className="row">
          <div className="col-3">
            <img src={this.props.artikel.images[0]} width="75" alt={this.props.artikel.title} />
          </div>
          <div className="col-9">
            {this.props.artikel.title}
            <ReviewsBlock rating={this.props.artikel.rating} reviews={this.props.artikel.reviews} short={true} />
            <PriceBlock oldPrice={this.props.artikel.oldPrice} currentPrice={this.props.artikel.price} short={true}/>
          </div>
        </div>
      </Link>
    );
  }
}

interface IProps {
  artikel: any,
}