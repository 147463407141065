import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export class Highlights extends React.Component <IProps> {
  render() {
    const highlights = this.props.highlights.map((highlight:any) => {
      return (
        <div className="col-auto" key={highlight}>
          <div className="bg-berger-lightgreen px-2 py-1 my-1">
            <FontAwesomeIcon icon={['fas', 'check']} className="text-success mr-2"/>
            {highlight}
          </div>
        </div>
      );
    })
    return (
      <div className="row my-3">
        {highlights}
      </div>
    );
  }
}

interface IProps {
  highlights: any,
}
