import * as React from "react";
import ProductscanImage from "../images/Barcodescan.svg";
import {Footer} from "../components/Footer";
import {Redirect} from "react-router";

export class Vorteilskartescan extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.props.toggleHideHome(false);
    this.props.toggleShowBack(true);
    this.props.toggleIsForm(false);
    this.state = {
      hasScanned: false,
      barcode: '',
      kundennummer: '',
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown (ev: KeyboardEvent): void {
    ev.preventDefault();
    const {keyCode, key} = ev;
    if (keyCode === 40) {
      this.setState({
        barcode: '',
        kundennummer: '',
      });
    } else if (keyCode === 13) {
      if (this.state.barcode !== '') {
        this.setState({
          hasScanned: true,
          kundennummer: this.handleCustomernumber(this.state.barcode),
        });

        document.removeEventListener('keydown', this.handleKeyDown, false);
      }
    } else {
      this.setState({
        barcode: this.state.barcode + key,
        kundennummer: '',
      });
    }
  }

  handleCustomernumber (cn: string): string {
    return cn.substr(4,8);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  render() {
    if (!this.state.hasScanned) {
      return (
        <main id="content" className="with-footer">
          <div id="start" className="page level-1">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="barcode-image">
                    <img src={ProductscanImage} alt={"Produkt scannen"}/>
                  </div>
                </div>
                <div className="col-8 d-flex align-items-center">
                  <div className="box box-gray arrow arrow-down flex-2 scanbox">
                    <h1 className={"text-berger-blue"}>Kundenkarte scannen</h1>
                    <p>Bitte lesen Sie den Barcode auf der Rückseite Ihrer Kundenkarte mithilfe des Scanners ein. Der
                      Scanner befindet sich am unteren Ende des Bildschirms. Halten Sie Ihre Kundenkarte einige Sekunden
                      unter den Scanner bis ein Signalton erklingt.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </main>
      );
    } else {
      return <Redirect push to={'/vorteilskarte/neukunde/' + this.state.kundennummer}/>;
    }
  };
}

interface IProps {
  toggleShowBack: (newShowBackState: boolean) => any
  toggleIsForm: (newIsFormState: boolean) => any
  toggleHideHome: (newShowHomeState: boolean) => any
}

interface IState {
  hasScanned: boolean
  barcode: string
  kundennummer: string
}
