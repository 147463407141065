import * as React from 'react';

export class Toast extends React.Component {
  render() {
    return (
      <div className="toast bg-danger text-white show" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header bg-danger text-white">
            <strong className="mr-auto">Uups..., falscher Code gelesen.</strong>
        </div>
        <div className="toast-body">
          Bitte verdecken Sie andere Strich- oder QR-Codes auf der Verpackung und scannen Sie erneut.
        </div>
      </div>
    );
  }
}
