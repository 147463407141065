import * as React from "react";

export const FormSubmit: React.FunctionComponent<IFormSubmitProps> = ({
  classes,
}) => {
  return (
    <button type="submit" className={classes}>Absenden</button>
  );
}

interface IFormSubmitProps {
  classes?: string
}