import * as React from "react";
import {
  Form,
  IFields,
  checkIsAdult,
  isCustomernumber,
  isEmail,
  isHousenumber,
  isNumeric,
  isTelephone,
  required
} from "../components/Form";
import {FormField, IOptions} from "../components/FormField";
import {FormSubmit} from "../components/FormSubmit";

export class Neukunde extends React.Component<IProps> {

  constructor(props: IProps) {
    super(props);
    this.props.toggleHideHome(false);
    this.props.toggleShowBack(true);
    this.props.toggleIsForm(false);
  }

  static defaultProps = {
    formAction: "https://info.berger-data.de/api/v1/kundenkarte"
  }

  render() {
    const anreden: IOptions[] = [
      {value: '', label: 'Bitte wählen'},
      {value: 'Herr'},
      {value: 'Frau'},
      {value: 'Familie'},
    ];
    const title: IOptions[] = [
      {value: ''},
      {value: 'Baron'},
      {value: 'Dr.'},
      {value: 'Dipl. Ing.'},
      {value: 'Dipl. Kfm.'},
      {value: 'Ing.'},
      {value: 'Mag.'},
      {value: 'Prof.'},
      {value: 'Prof. Dr.'},
      {value: 'Prof. Dr. Dr.'},
      {value: 'Dipl. Inf.'},
      {value: 'Dr. Dr.'},
    ];
    const laender: IOptions[] = [
      {value: '', label: 'Bitte wählen'},
      {value: 'DE', label: 'Deutschland'},
      {value: 'AT', label: 'Österreich'},
      {value: 'CH', label: 'Schweiz'},
      {value: 'BE', label: 'Belgien'},
      {value: 'BG', label: 'Bulgarien'},
      {value: 'DK', label: 'Dänemark'},
      {value: 'EE', label: 'Estland'},
      {value: 'FI', label: 'Finnland'},
      {value: 'FR', label: 'Frankreich'},
      {value: 'GR', label: 'Griechenland'},
      {value: 'GB', label: 'Großbritannien'},
      {value: 'IE', label: 'Irland'},
      {value: 'IT', label: 'Italien'},
      {value: 'HR', label: 'Kroatien'},
      {value: 'LV', label: 'Lettland'},
      {value: 'LI', label: 'Liechtenstein'},
      {value: 'LT', label: 'Litauen'},
      {value: 'LU', label: 'Luxemburg'},
      {value: 'MT', label: 'Malta'},
      {value: 'NL', label: 'Niederlande'},
      {value: 'NO', label: 'Norwegen'},
      {value: 'PL', label: 'Polen'},
      {value: 'PT', label: 'Portugal'},
      {value: 'RO', label: 'Rumänien'},
      {value: 'SE', label: 'Schweden'},
      {value: 'SK', label: 'Slowakei'},
      {value: 'SI', label: 'Slowenien'},
      {value: 'ES', label: 'Spanien'},
      {value: 'CZ', label: 'Tschechische Republik'},
      {value: 'HU', label: 'Ungarn'},
    ];
    const filialen: IOptions[] = [
      {value: '', label: 'Bitte wählen'},
      {value: 'Almere (NL)'},
      {value: 'Ansbach'},
      {value: 'Augsburg/ Lechhausen'},
      {value: 'Bad Bergzadern'},
      {value: 'Bad Füssing'},
      {value: 'Bad Kreuznach'},
      {value: 'Bergkirchen'},
      {value: 'Berlin (Lichterfelde)'},
      {value: 'Berlin (Tegel)'},
      {value: 'Bielefeld (Brake)'},
      {value: 'Bindlach'},
      {value: 'Bischofsheim'},
      {value: 'Bliesen'},
      {value: 'Braunschweig'},
      {value: 'Buchholz'},
      {value: 'Deggendorf'},
      {value: 'Dettingen unter Teck'},
      {value: 'Dörfles-Esbach (Coburg)'},
      {value: 'Eisenach'},
      {value: 'Ellingen (Weißenburg)'},
      {value: 'Erfurt'},
      {value: 'Eriskirch'},
      {value: 'Frankfurt am Main'},
      {value: 'Freiburg'},
      {value: 'Gießen'},
      {value: 'Grafenau (Döffingen)'},
      {value: 'Gütersloh'},
      {value: 'Heide'},
      {value: 'Heidelberg'},
      {value: 'Heiligenzimmern (Rosenfeld)'},
      {value: 'Hooksiel'},
      {value: 'Iphofen'},
      {value: 'Isny im Allgäu'},
      {value: 'Jülich'},
      {value: 'Kenn'},
      {value: 'Kerpen (Sindorf)'},
      {value: 'Kesselsdorf (Dresden)'},
      {value: 'Kolbermoor'},
      {value: 'Losheim am See'},
      {value: 'Moormerland'},
      {value: 'Möser (Magdeburg)'},
      {value: 'Mülheim an der Ruhr'},
      {value: 'Mülheim-Kärlich'},
      {value: 'Neuenburg am Rhein'},
      {value: 'Neumarkt'},
      {value: 'Neustadt Dosse'},
      {value: 'Neustrelitz'},
      {value: 'Nieuwegein (NL)'},
      {value: 'Nottuln'},
      {value: 'Oberhausen'},
      {value: 'Offenburg'},
      {value: 'Overath'},
      {value: 'Pfullingen'},
      {value: 'Quickborn (Pinneberg)'},
      {value: 'Regensburg'},
      {value: 'Reinfeld'},
      {value: 'Remshalden'},
      {value: 'Rheinbach'},
      {value: 'Salzburg (AT)'},
      {value: 'Schongau'},
      {value: 'Senden'},
      {value: 'Stein (NL)'},
      {value: 'Stuhr/Groß-Mackenstedt'},
      {value: 'Stuttgart-Weilimdorf'},
      {value: 'Trier'},
      {value: 'Unterhaching'},
      {value: 'Upgant-Schott (Marienhafe)'},
      {value: 'Vösendorf/Wiener Neudorf (AT)'},
      {value: 'Waldstetten'},
      {value: 'Warendorf'},
      {value: 'Wesseling'},
      {value: 'Weyhausen'},
      {value: 'Witten'},
      {value: 'Wuppertal'},
      {value: 'Wutöschingen (Horheim)'},
    ];
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + 1);
    currDate.setFullYear(currDate.getFullYear() - 18);
    const defaultDate = currDate.toISOString().substr(0, 10);
    let fields: IFields = {
      anrede: {
        id: "anrede",
        label: "Anrede",
        editor: "select",
        options: anreden,
        validation: {rule: required}
      },
      titel: {
        id: "titel",
        label: "Titel",
        editor: "select",
        options: title
      },
      vorname: {
        id: "vorname",
        label: "Vorname",
        validation: {rule: required}
      },
      nachname: {
        id: "nachname",
        label: "Nachname",
        validation: {rule: required}
      },
      kundennummer: {
        id: "kundennummer",
        label: "Kundennummer",
        type: "hidden",
        inputmode: "numeric",
        disabled: true,
        value: this.props.match.params.id,
        validation: {rule: isCustomernumber}
      },
      strasse: {
        id: "strasse",
        label: "Straße",
        validation: {rule: required}
      },
      hausnummer: {
        id: "hausnummer",
        label: "Hausnummer",
        type: "number",
        validation: {rule: isHousenumber}
      },
      plz: {
        id: "plz",
        label: "PLZ",
        inputmode: "numeric",
        validation: {rule: isNumeric}
      },
      ort: {
        id: "ort",
        label: "Ort",
        validation: {rule: required}
      },
      adresszusatz: {
        id: "adresszusatz",
        label: "Adresszusatz"
      },
      land: {
        id: "land",
        label: "Land",
        editor: "select",
        options: laender,
        validation: {rule: required}
      },
      telefon: {
        id: "telefon",
        label: "Telefon",
        type: "tel",
        validation: {rule: isTelephone}
      },
      email: {
        id: "email",
        label: "E-Mail-Adresse",
        type: "email",
        validation: {rule: isEmail}
      },
      geburtsdatum: {
        id: "geburtsdatum",
        label: "Geburtsdatum",
        type: "date",
        defaultValue: defaultDate,
        validation: {rule: checkIsAdult}
      },
      filiale: {
        id: "filiale",
        label: "Nächstgelegene Filiale",
        editor: "select",
        options: filialen,
        validation: {rule: required}
      },
      newsletter: {
        id: "newsletter",
        editor: "checkbox",
        value: 1,
        label: "Berger Newsletter - Keine Angebote & Aktionen verpassen!",
        subtext: "newsletter",
      },
      vorteilskarte: {
        id: "vorteilskarte",
        editor: "checkbox",
        value: 1,
        label: "Berger Vorteilskarte",
        subtext: "vorteilskarte",
        validation: {rule: required}
      },
    }

    return (
      <main id="content" className="with-footer">
        <div id="kunde-registrierung" className="page level-2 kunde">
          <div className="container">
            <Form action={this.props.formAction} fields={fields} render={() => (
              <div className="row">
                <div className="col px-4">
                  <div className="box box-gray arrow arrow-right arrow-top">
                    <h1 className={"text-berger-blue"}>Und so funktioniert es:</h1>
                    <ol className="steps">
                      <li>Entnehmen Sie ein Formular mit der darin enthaltenen Vorteilskartes aus dem Aufsteller.</li>
                      <li>Tragen Sie Ihre Daten und die auf der Vorteilskarte angegebene Kundennummer in das Formular ein.</li>
                      <li>Bestätigen Sie die Teilnahmebedingungen und die Datenschutzerklärung.</li>
                      <li>Fertig! Sie können Ihre neu erworbene Vorteilskarte jetzt nutzen.</li>
                    </ol>
                  </div>
                </div>
                <div className="col px-4">
                  <FormField {...fields.kundennummer} />
                  <div className="row">
                    <div className="col">
                      <FormField {...fields.anrede}/>
                    </div>
                    <div className="col">
                      <FormField {...fields.titel}/>
                    </div>
                  </div>
                  <FormField {...fields.vorname} />
                  <FormField {...fields.nachname} />
                  <div className="row">
                    <div className="col-8">
                      <FormField {...fields.strasse} />
                    </div>
                    <div className="col-4">
                      <FormField {...fields.hausnummer} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4">
                      <FormField {...fields.plz} />
                    </div>
                    <div className="col-8">
                      <FormField {...fields.ort} />
                    </div>
                  </div>
                  <FormField {...fields.adresszusatz} />
                  <FormField {...fields.land} />
                </div>
                <div className="col px-4">
                  <FormField {...fields.telefon} />
                  <FormField {...fields.email} />
                  <FormField {...fields.geburtsdatum} />
                  <FormField {...fields.filiale} />
                  <FormField {...fields.newsletter} />
                  <FormField {...fields.vorteilskarte} />
                  <FormSubmit classes="btn btn-lg btn-block btn-berger-red" />
                  <p><small>Die mit einem (*) gekennzeichneten Felder sind Pflichtfelder.</small></p>
                </div>
              </div>
            )} />
          </div>
        </div>
        <footer>
          <div className="container h-100">
            <ul className="row list-unstyled justify-content-between align-items-center h-100">
              <li className="features">Bis zu 5% Bonus</li>
              <li className="features">100 Tage Rückgaberecht</li>
              <li className="features">Umtausch ohne Kassenbon</li>
              <li className="features">Campingplatzvorteile</li>
            </ul>
          </div>
        </footer>
      </main>
    );
  };
}

interface IProps {
  toggleShowBack: (newShowBackState: boolean) => any
  toggleIsForm: (newIsFormState: boolean) => any
  toggleHideHome: (newShowHomeState: boolean) => any
  formAction: string
  match: any
}
