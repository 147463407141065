import * as React from 'react';
import '../fontawesome';

export class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container d-flex justify-content-center">
          <div className="footer-scanner">
            <div className="pfeil-scanner" />
            <div className="scanner-hinweis">
              Barcodescanner
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
