import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import '../fontawesome';
import Background from '../images/Startseite_Hintergrund.jpg';

export class Start extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.props.toggleIsHome(true);
  }

  render() {
    return (
      <main id="content">
        <div id="start" className="page level-0">
          <div id="background-image" className="container">
            <img src={Background} alt={'berger'}/>
          </div>
          <div className="container">
            <div className="row justify-content-center flex-grow-1">
              <div className="card">
                <Link to="/vorteilskarte" className="kachel vorteilskarte w-100"
                      onClick={() => this.props.toggleIsHome(false)}>
                  <FontAwesomeIcon icon={['fas', 'credit-card']}/>
                  <div className="d-flex justify-content-between w-100">
                    <span>Vorteilskarte<br/>beantragen</span>
                    <span><FontAwesomeIcon icon={['fas', 'chevron-right']}/></span>
                  </div>
                </Link>
              </div>
              <div className="card">
                <Link to="/produktsuche" className="kachel produktscan w-100"
                      onClick={() => this.props.toggleIsHome(false)}>
                  <FontAwesomeIcon icon={['fas', 'barcode']}/>
                  <div className="d-flex justify-content-between w-100">
                    <span>Produkt-<br/>informationen</span>
                    <span><FontAwesomeIcon icon={['fas', 'chevron-right']}/></span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

interface IProps {
  toggleIsHome: (newIsHomeState: boolean) => any
}
