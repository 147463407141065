import React from "react";
import {AppContext} from "../pages/App";

export class Subtext extends React.Component<IProps, IState> {
  render() {
    if (this.props.type === "newsletter") {
      return (
        <p>
          <small>
            Bitte senden Sie mir entsprechend Ihrer Datenschutzerklärung regelmäßig und jederzeit widerruflich, auf
            meine persönlichen Interessen zugeschnittene Informationen zu folgendem Produktsortiment per E-Mail zu:
            Camping- und Freizeitzubehör Outdoor-Bekleidung, Fahrzeuge und Magazine. Sie können den Newsletter jederzeit
            abbestellen und die Einwilligung zur Datennutzung für Zwecke der Werbung, Markt- und Meinungsforschung
            widerrufen. Einen entsprechenden Link finden Sie am Ende jedes Newsletters.
          </small>
        </p>
      );
    } else if (this.props.type === "vorteilskarte") {
      return (
        <AppContext.Consumer>
          {appContext => appContext && (
            <p>
              <small>
                Ich beantrage die kostenlose Berger Vorteilskarte und habe die <span
                  onClick={
                    (e: React.MouseEvent) => {
                      e.preventDefault();
                      appContext.setShowModal(true);
                    }
                  }
                  className={"link"}>Teilnahmebedingungen</span> und <span
                onClick={
                  (e: React.MouseEvent) => {
                    e.preventDefault();
                    appContext.setShowModal(true);
                  }
                }
                className={"link"}>Datenschutzerklärung</span> zum Vorteilskarten-Bonusprogramm zur Kenntnis genommen.
              </small>
            </p>
          )}
        </AppContext.Consumer>
      );
    } else {
      return null;
    }
  }
}

interface IProps {
  type: string
}

interface IState {
}